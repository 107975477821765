.modal {
    position: absolute;
    top: 0; 
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255,255,255,0.8);
    display: flex;
    align-items: center; 
    justify-content: center;
    z-index: 99999999;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background: #fff;
}